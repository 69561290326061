// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket:
    'sonic-marketplace-20190414012556-hostingbucket-prod',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d9uoo0d8d1rlk.cloudfront.net',
  aws_cognito_identity_pool_id:
    'us-east-1:80f5c276-cd8a-48fc-ac14-c31df7533667',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_SWmFzvnku',
  aws_user_pools_web_client_id: '5dromsrnopienmqa83ba4n927h',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'MarketPlace',
      endpoint: 'https://poh3jvlrri.execute-api.us-east-1.amazonaws.com/prod',
      region: 'us-east-1',
    },
  ],
};

export default awsmobile;
